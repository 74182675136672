import React, { useCallback, useEffect, useState } from 'react'
import 'react-credit-cards/es/styles-compiled.css'
import Button from '@material-ui/core/Button'
import styles from './ReturnForm.module.scss'
import { FormattedMessage, useIntl } from 'react-intl'
import { ErrorMessage } from "../ErrorMessage/ErrorMessage"
import { useSelector } from "react-redux";
import { sbankInvoiceStatuses } from "../../pages/FinishPage/FinishPage";

export const ReturnForm = props => {
  const [url, setUrl] = useState(null)

  const finish = useSelector(state => state.finish)

  useEffect(() => {
    const {payment_status, error_url, finish_url, success_url} = finish.data
    if(payment_status === sbankInvoiceStatuses.COMPLETE) {
      setUrl(success_url)
      return
    }
    if(payment_status === sbankInvoiceStatuses.FAILED) {
      setUrl(error_url)
      return
    }
    if(finish_url && payment_status === sbankInvoiceStatuses.COMPLETE || payment_status === sbankInvoiceStatuses.FAILED) {
      setUrl(finish_url)
      return
    }
    setUrl('')
  }, [finish.data])

  const callback = useCallback(() => {
    document.location.href = url
  }, [url])

  return (
    <div className={styles.form}>
      <div>
        {/*{!!(fatalMessages && fatalMessages.length) && (*/}
        {/*  <ErrorMessage errors={fatalMessages}/>*/}
        {/*)}*/}

        {url && (
          <Button
            variant="contained"
            color="primary"
            className={styles.payBtn}
            onClick={callback}
          >
            <FormattedMessage id='return.btn.return'/>
          </Button>
        )}
      </div>
    </div>
  )
}
